import React from 'react'
import { PageContext, Article } from '../lib/sanity-types'
import { DetailPage } from '../components/detail-page'

const ServicePage: React.FC<{
  pageContext: PageContext & { article: Article }
}> = ({ pageContext }) => {
  return (
    <DetailPage
      seo={pageContext.article.seo}
      pageContext={pageContext}
      name={pageContext.article.title}
      description={pageContext.article.description}
      content={pageContext.article.content}
    />
  )
}

export default ServicePage
